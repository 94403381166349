import { ReactElement, useContext, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Button, Divider, Tooltip } from '@breathelife/mui';

import styled, { useTheme } from '../../Styles/themed-styled-components';
import urls from '../../Navigation/Urls';
import { NotificationCentreIcon } from '../../Pages/Home/NotificationCentre/NotificationCentreIcon';
import { useCarrierContext } from '../../Hooks';
import { handleColorsVariant } from '../Sidebar/Sidebar';
import { CustomersIcon } from '../../Assets/Icons/CustomersIcon';
import { AdminIcon } from '../../Assets/Icons/AdminIcon';
import { Permission } from '@breathelife/types';
import { RestrictedToUserPermissions } from '../Restricted/RestrictedToUserPermissions';
import { SidebarNavLink } from '../Sidebar/SidebarNavLink';
import { UserMenuContext } from '../../Context/UserMenuContextProvider';
import Typography from '../Typography';

const MobileMenuContainer = styled(Box).withConfig({
  shouldForwardProp: (prop) => !['hideMenuOverride'].includes(prop),
})<{ hideMenuOverride: boolean }>`
  display: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[30]};
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.grey[10]};

  @media (max-width: ${(props) => props.theme.moduleBreakpoints.md}) {
    display: ${(props) => (props.hideMenuOverride ? 'none' : 'flex')};
  }
`;

const HamburgerIcon = styled.div`
  height: 27px;
  width: 35px;
  cursor: pointer;
  transition: opacity 0.25s ease;
  margin-left: 10px;

  &.active {
    .top {
      transform: translateY(11px) translateX(0) rotate(45deg);
    }
    .middle {
      opacity: 0;
    }

    .bottom {
      transform: translateY(-9px) translateX(0) rotate(-45deg);
    }
  }

  span {
    display: block;
    margin-bottom: 5px;
    background: ${(props) => props.theme.colors.primary.default};
    border: none;
    height: 5px;
    width: 100%;
    transition: all 0.35s ease;
    cursor: pointer;

    &:nth-of-type(2) {
      top: 11px;
    }

    &:nth-of-type(3) {
      top: 22px;
    }
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  * {
    margin: 0 !important;
  }
`;

const MobileMenuHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px;
  background-color: ${(props) => props.theme.colors.grey[10]};
`;

const RightMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MenuContent = styled.div`
  display: none;
  background-color: ${(props) => props.theme.colors.grey[30]} !important;

  &.open {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

const UserMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;

  button {
    width: 80%;
    margin-bottom: 15px;

    span {
      font-size: 20px !important;
    }

    svg {
      margin-right: 10px;
    }
  }
`;

const NavLinks = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${(props) => props.theme.colors.grey[30]} !important;
  width: 50%;

  a {
    background-color: ${(props) => props.theme.colors.grey[60]} !important;

    &.active {
      background-color: ${(props) => props.theme.colors.secondary[70]} !important;
    }

    span,
    svg {
      color: ${(props) => props.theme.colors.grey[10]} !important;
      stroke: ${(props) => props.theme.colors.grey[10]} !important;
    }
  }
`;

type MobileMenuProps = {
  logo: ReactElement;
};

export function MobileMenu(props: MobileMenuProps): ReactElement {
  const theme = useTheme();
  const { features, navigationSidebarVariant } = useCarrierContext();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const isAssistedApplicationPath = !!matchPath(urls.application.fullPath, pathname);

  const styles = handleColorsVariant(theme, navigationSidebarVariant);
  const sidebarNavLinks = [
    {
      url: urls.leads.fullPath,
      text: features.hiddenLeads?.enabled ? t('sidebar.applications') : t('sidebar.customers'),
      icon: <CustomersIcon stroke={theme.colors.grey[styles.grey]} />,
    },
    {
      url: urls.admin.fullPath,
      text: t('sidebar.admin'),
      icon: <AdminIcon stroke={theme.colors.grey[styles.grey]} />,
      requiredPermissions: [Permission.AdminToolsRead],
    },
  ];

  const { items } = useContext(UserMenuContext);

  return (
    <MobileMenuContainer hideMenuOverride={isAssistedApplicationPath}>
      <MobileMenuHeader>
        <Logo>{props.logo}</Logo>
        <RightMenu>
          <NotificationCentreIcon isMobile />
          <HamburgerIcon className={open ? 'active' : ''} onClick={() => setOpen(!open)}>
            <span className='top'></span>
            <span className='middle'></span>
            <span className='bottom'></span>
          </HamburgerIcon>
        </RightMenu>
      </MobileMenuHeader>

      <MenuContent className={open ? 'open' : ''}>
        <NavLinks>
          {sidebarNavLinks.map((navLink, key) => {
            return (
              <RestrictedToUserPermissions key={key} requiredPermissions={[Permission.AdminToolsRead]}>
                <SidebarNavLink
                  to={navLink.url}
                  text={navLink.text}
                  icon={navLink.icon}
                  greyShade={styles.grey}
                  activeColor={styles.activeColor}
                  hoverColor={styles.hoverColor}
                />
              </RestrictedToUserPermissions>
            );
          })}
        </NavLinks>
        <UserMenu>
          {items.map((item, key) => {
            switch (item.tag) {
              case 'MenuDivider':
                return <Divider key={`MenuDivider-${key}`} />;
              case 'MenuAction':
                const menuItem = (
                  <Button
                    key={`MenuItem-${key}`}
                    onClick={item.onClick}
                    disabled={item.disabled}
                    data-testid={item['data-testid']}
                    {...item.linkProperties}
                  >
                    {item.icon}
                    <Typography variant='body3' grey={90}>
                      {item.label}
                    </Typography>
                  </Button>
                );

                if (item.tooltip)
                  return (
                    <Tooltip title={item.tooltip} key={`MenuItemTooltip-${key}`}>
                      <div>{menuItem}</div>
                    </Tooltip>
                  );
                return menuItem;
            }
          })}
        </UserMenu>
      </MenuContent>
    </MobileMenuContainer>
  );
}
